import * as React from "react";
import type { HeadFC } from "gatsby";
import Layout from "../components/layout";
import "../styles/global.css";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Raleway, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main className="bg-white" style={pageStyles}>
      <Layout>
        {/* hero */}
        <div
          className="h-screen flex sm:flex-wrap"
          style={{
            backgroundColor: "#faf4f2",
          }}
        >
          <div className="absolute lg:static z-10 place-self-center lg:flex w-full lg:w-1/2 login_img_section justify-around items-center px-2">
            <div className="mx-auto max-w-[43rem] flex-auto">
              <div className="text-center">
                <h1 className="text-[3.5rem] font-bold leading-[4rem] tracking-tight text-black">
                  "EL <span className="underlined">AMOR</span> TE ELIGE A{" "}
                  <span className="text-blue-600">TI</span>"
                </h1>
                <p
                  className="text-[1.5rem] mx-2 my-4"
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  “EL SECRETO DE LA EXISTENCIA NO CONSISTE EN VIVIR, SINO EN SABER PARA 
                  QUÉ SE VIVE”
                </p>
                <p
                  className="text-[1rem]"
                  style={{
                    fontFamily: "Raleway",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Trabajamos desarrollando las habilidades de acuerdo a la
                  discapacidad de cada niño.
                </p>
              </div>

              <div className="mt-6 flex items-center justify-center gap-4">
                <a href="/charity" className="button-class bg-blue-600 text-white">
                  {" "}
                  DONAR{" "}
                </a>
              </div>
            </div>
          </div>
          <div
            className="flex w-full lg:w-1/2 justify-center items-center space-y-8 relative after:bg-white after:bg-opacity-75 lg:after:bg-opacity-0 after:absolute after:content-[\' \'] after:top-0 after:right-0 after:bottom-0 after:left-0 after:h-full after:w-full"
            style={{
              backgroundImage: "url(/images/main.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "auto 100%",
            }}
          >
            <div className="w-full px-8 md:px-32 lg:px-24"></div>
          </div>
        </div>
        {/* end hero */}

        {/* divisor */}

        <div className="grid grid-col-1 md:grid-col-5 lg:grid-cols-5 h-full lg:h-96">
          
          <div
            className="text-white text-center sm:text-center h-full w-full"
            style={{
              backgroundImage: "url(/images/division-one.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "100% 100%",
            }}
          ></div>

          <div
            className="text-white text-center sm:text-center h-full w-full"
            style={{
              backgroundImage: "url(/images/division-three.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "100% 100%",
            }}
          ></div>

          <div
            className=" text-white text-center sm:text-center h-full w-full"
            style={{
              backgroundImage: "url(/images/division-six.png)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "100% 100%",
            }}
          ></div>

          <div
            className=" text-white text-center sm:text-center h-full  w-full"
            style={{
              backgroundImage: "url(/images/division-four.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "100% auto",
            }}
          ></div>

          <div
            className=" text-white text-center sm:text-center h-full  w-full"
            style={{
              backgroundImage: "url(/images/division-five.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              color: "#232129",
              backgroundSize: "100% auto",
            }}
          ></div>

        </div>
        {/* end divisor */}

        {/* about */}
        <div className="w-full  mb-10 mx-auto bg-blue-600 h-100 flex items-center p-2">
          <div className="sm:ml-20 text-gray-50 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4 p-4">¿Quiénes Somos?</h1>
            <p className="text-lg inline-block sm:block p-6">
             Somos un grupo de padres unidos y conscientes de las necesidades
             de nuestros hijos y la población. Es por eso, que al vivir día
              a día la discapacidad nos motiva a que nuestros hijos tengan 
              una mejor calidad de vida y que en el futuro sean autónomos 
              y productivos ante la sociedad.
              <br /><br />
              Una motivación para nuestra institución es el amor hacia 
              nuestros hijos, situación que nos lleva a que por medio de la
              institución mejoren sus oportunidades y tengan una mejor 
              calidad de vida con un reconocimiento e inserción social.
            </p>
          </div>
        </div>

        <div className="lg:mt-10 container mx-auto bg-yellow-300 h-96 flex items-center">
          <div className="sm:ml-20 text-gray-50 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4 text-black">Misión</h1>
            <p className="text-lg inline-block sm:block text-black px-2">
              Desarrollar las habilidades intelectuales y académicas de los
              niños con discapacidad intelectual y con problemas de aprendizaje,
              para lograr su inclusión integral a los diferentes contextos
              sociales.
            </p>
          </div>
        </div>

        <div className="lg:mt-10 container mx-auto bg-green-400 h-96 flex items-center mb-10">
          <div className="sm:ml-20 text-gray-50 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4 text-black">Visión</h1>
            <p className="text-lg inline-block sm:block text-black">
              Ser una institución que brinde atención comprometida y profesional
              a niños con discapacidad intelectual, con el apoyo terapéutico
              corresponsable entre padres de familia y personal; con la
              finalidad de mejorar la calidad de vida de nuestros niños y la de
              su familia.
            </p>
          </div>
        </div>

        <div className="w-full  mb-10 mx-auto bg-blue-600 h-96 flex items-center">
          <div className="sm:ml-20 text-gray-50 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4">¿Cómo lo hacemos?</h1>
            <p className="text-lg inline-block sm:block">
              Una motivación para nuestra institución es el amor hacia nuestros
              hijos, situación que nos lleva a que por medio de la institución
              mejoren sus oportunidades y tengan una mejor calidad de vida con
              un reconocimiento e inserción socia
            </p>
          </div>
        </div>

        <div className="container mx-auto flex items-center">
          <div className="grid grid-cols-6 col-span-2 gap-2 mt-10">
            <div className=" overflow-hidden rounded-xl col-span-3 max-h-[24rem]">
              <img
                className="h-full w-full object-cover "
                src="/images/a1.jpeg"
              />
            </div>
            <div className=" overflow-hidden rounded-xl col-span-3 max-h-[24rem]">
              <img
                className="h-full w-full object-cover  "
                src="/images/a2.jpeg"
              />
            </div>
            <div className=" overflow-hidden rounded-xl col-span-2 max-h-[15rem]">
              <img
                className="h-full w-full object-cover "
                src="/images/a3.jpeg"
              />
            </div>
            <div className=" overflow-hidden rounded-xl col-span-2 max-h-[15rem]">
              <img
                className="h-full w-full object-cover "
                src="/images/a4.jpeg"
              />
            </div>
           
            <div className="relative overflow-hidden rounded-xl col-span-2 max-h-[15rem]">
            <button className="">
              <a href="/gallery">
              <div className="text-white text-xl absolute inset-0  bg-slate-900/80 flex justify-center items-center">
                + 23
              </div> 
              
              <img
                className="h-full w-full object-cover "
                src="/images/a5.jpeg"
              /></a>
            </button>
            </div>
            
          </div>
        </div>
      </Layout>
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>El Amor Te Elige A Ti</title>;
